<template>
	<div class="main" :class="{ showstyle: showyes }">
		<!-- 移入显示隐藏 -->
		<div
			style="
				position: fixed;
				right: 5%;
				top: 60%;
				width: 68px;
				height: 150px;
				border-radius: 4px;
				border: 1.06px #e6e6e6 solid;
				background: #fff;
				z-index: 9999;
			"
		>
			<div class="was">
				<img v-if="showgz" src="../assets/img/资质狗公众号二维码.jpg" alt="" />
				<img v-if="showcx" src="../assets/img/资质狗小程序.jpg" alt="" />
			</div>
			<div class="mouser" @mouseleave="out" @mouseenter="handleMouseEnter" style="border-bottom: 1.06px #e6e6e6 solid">
				<img src="../assets/img/Frame (4).png" alt="" />
				<p>公众号</p>
			</div>

			<div class="mouser" @mouseleave="onew" @mouseenter="twow">
				<img src="../assets/img/Frame (4).png" alt="" />
				<p>小程序</p>
			</div>
		</div>
		<div class="header">
			<!-- 版心 -->
			<div class="top"></div>

			<!-- 头部 -->

			<div class="top" style="position: fixed; top: 0; z-index: 999">
				<div class="container" style="display: flex; justify-content: space-between; align-items: center">
					<!-- logo -->
					<div><img src="../assets/img/Group 5154.png" alt="" /></div>
					<!-- 首页导航 -->
					<div class="headerbox">
						<p><a style="text-decoration: none" href="#first">首页</a></p>
						<p><a style="text-decoration: none" href="#second">热门领域</a></p>
						<p><a style="text-decoration: none" href="#third">一站式服务</a></p>
						<p><a style="text-decoration: none" href="#four">资质优势</a></p>
						<p><a style="text-decoration: none" href="#five">资质服务</a></p>
					</div>
				</div>
			</div>

			<!-- 轮播图 -->
			<div class="swiper" id="first">
				<el-carousel :interval="interval" arrow="nerver">
					<el-carousel-item>
						<img src="@/assets/img/Group-5156.png" alt="" class="swiperimg" />
					</el-carousel-item>
					<el-carousel-item>
						<img src="@/assets/img/Group-5156.png" class="swiperimg" alt="" />
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<!-- 资源分类 -->
		<div class="categroy">
			<div class="container">
				<p class="font-cate">资质分类</p>
				<div class="son-box">
					<div class="box-cate">
						<div>
							<img style="margin: 40px 0 0 0" src="../assets/img/编组备份 (2).png" alt="" />
						</div>
						<div>
							<p
								style="
									color: #333333;
									font-size: 16px;
									font-family: PingFang SC;
									font-weight: 500;
									line-height: 16px;
									word-wrap: break-word;
									margin: 20px 0 10px 0;
								"
							>
								施工资质
							</p>
							<p
								style="
									color: #808080;
									font-size: 14px;
									font-family: PingFang SC;
									font-weight: 400;
									line-height: 22px;
									word-wrap: break-word;
									width: 225px;
									margin: 0 auto;
								"
							>
								建筑施工资质 、电力施工资质、市政施工资质等
							</p>
						</div>
					</div>
					<div class="box-cate">
						<div>
							<img style="margin: 40px 0 0 0" src="../assets/img/编组备份 (1).png" alt="" />
						</div>
						<div>
							<p
								style="
									color: #333333;
									font-size: 16px;
									font-family: PingFang SC;
									font-weight: 500;
									line-height: 16px;
									word-wrap: break-word;
									margin: 20px 0 10px 0;
								"
							>
								设计资质
							</p>
							<p
								style="
									color: #808080;
									font-size: 14px;
									font-family: PingFang SC;
									font-weight: 400;
									line-height: 22px;
									word-wrap: break-word;
									width: 225px;
									margin: 0 auto;
								"
							>
								装饰设计、钢结构设计、环境工程设计、智能化设计等
							</p>
						</div>
					</div>
					<div class="box-cate">
						<div>
							<img style="margin: 40px 0 0 0" src="../assets/img/编组备份.png" alt="" />
						</div>
						<div>
							<p
								style="
									color: #333333;
									font-size: 16px;
									font-family: PingFang SC;
									font-weight: 500;
									line-height: 16px;
									word-wrap: break-word;
									margin: 20px 0 10px 0;
								"
							>
								监理资质
							</p>
							<p
								style="
									color: #808080;
									font-size: 14px;
									font-family: PingFang SC;
									font-weight: 400;
									line-height: 22px;
									word-wrap: break-word;
									width: 225px;
									margin: 0 auto;
								"
							>
								甲级监理资质，建筑、市政、园林、环境、公路、水利
							</p>
						</div>
					</div>
					<div class="box-cate">
						<div>
							<img style="margin: 40px 0 0 0" src="../assets/img/编组 7备份 2.png" alt="" />
						</div>
						<div>
							<p
								style="
									color: #333333;
									font-size: 16px;
									font-family: PingFang SC;
									font-weight: 500;
									line-height: 16px;
									word-wrap: break-word;
									margin: 20px 0 10px 0;
								"
							>
								勘察资质
							</p>
							<p
								style="
									color: #808080;
									font-size: 14px;
									font-family: PingFang SC;
									font-weight: 400;
									line-height: 22px;
									word-wrap: break-word;
									width: 225px;
									margin: 0 auto;
								"
							>
								工程勘察综合资质，工程勘察专业资质，工程勘察劳务资质
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 热门业务 -->
		<div class="hot" id="second">
			<div class="container">
				<p class="hot-font">热门业务领域</p>
				<div class="son-hot">
					<div class="hot-box">
						<div>
							<img src="../assets/img/Mask group.png" alt="" />
						</div>
						<div>
							<p
								style="
									font-family: PingFang SC;
									font-size: 18px;
									font-weight: 500;
									line-height: 22px;
									letter-spacing: 0px;
									text-align: left;
									width: 72px;
									margin: 20px auto 10px;
								"
							>
								设计资质
							</p>
							<p
								style="
									font-family: PingFang SC;
									font-size: 14px;
									font-weight: 400;
									line-height: 22px;
									letter-spacing: 0px;
									text-align: left;
									width: 237px;
									margin: 0 auto;
									color: #808080;
								"
							>
								建筑幕墙设计、智能化设计、环境设计、装饰装修设计。
							</p>
							<div class="ho" @click="clickcon">立即咨询</div>
						</div>
					</div>
					<div class="hot-box">
						<div>
							<img src="../assets/img/潮点图片_980104 (2).png" alt="" />
						</div>
						<div>
							<p
								style="
									font-family: PingFang SC;
									font-size: 18px;
									font-weight: 500;
									line-height: 22px;
									letter-spacing: 0px;
									text-align: left;
									width: 72px;
									margin: 20px auto 10px;
								"
							>
								资质转让
							</p>
							<p
								style="
									font-family: PingFang SC;
									font-size: 14px;
									font-weight: 400;
									line-height: 22px;
									letter-spacing: 0px;
									text-align: left;
									width: 237px;
									margin: 0 auto;
									color: #808080;
								"
							>
								转让建筑资质有两种类型，一种是整体转让，还有一种是剥离转让。
							</p>
							<div class="ho" @click="clickcon">立即咨询</div>
						</div>
					</div>
					<div class="hot-box">
						<div>
							<img src="../assets/img/潮点图片_878039.png" alt="" />
						</div>
						<div>
							<p
								style="
									font-family: PingFang SC;
									font-size: 18px;
									font-weight: 500;
									line-height: 22px;
									letter-spacing: 0px;
									text-align: left;
									width: 102px;
									margin: 20px auto 10px;
								"
							>
								房地产资质
							</p>
							<p
								style="
									font-family: PingFang SC;
									font-size: 14px;
									font-weight: 400;
									line-height: 22px;
									letter-spacing: 0px;
									text-align: left;
									width: 237px;
									margin: 0 auto;
									color: #808080;
								"
							>
								房地产开发企业资质按照企业条件为一、二、三、四等四个资质等级。
							</p>
							<div class="ho" @click="clickcon">立即咨询</div>
						</div>
					</div>
					<div class="hot-box">
						<div>
							<img src="../assets/img/潮点图片_929351.png" alt="" />
						</div>
						<div>
							<p
								style="
									font-family: PingFang SC;
									font-size: 18px;
									font-weight: 500;
									line-height: 22px;
									letter-spacing: 0px;
									text-align: left;
									width: 102px;
									margin: 20px auto 10px;
								"
							>
								规费取费证
							</p>
							<p
								style="
									font-family: PingFang SC;
									font-size: 14px;
									font-weight: 400;
									line-height: 22px;
									letter-spacing: 0px;
									text-align: left;
									width: 237px;
									margin: 0 auto;
									color: #808080;
								"
							>
								每年施工单位要将自己公司当年的规费系数上报政府有关部门审批，审批核准后下发规费证，当年改施工单位的工程。
							</p>
							<div class="hoh" @click="clickcon">立即咨询</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 一站式资质服务 -->
		<div class="service" id="third">
			<div class="container">
				<p class="service-font">一站式资质服务</p>
				<p class="service-spfont">申报时间短，通过率高，高效资质服务，省时省心</p>
				<div class="son-service">
					<div :class="{ active: activeTab === 'tab1' }" @click="activeTab = 'tab1'" class="deactive-box">
						总承包资质
					</div>
					<div :class="{ active: activeTab === 'tab2' }" @click="activeTab = 'tab2'" class="deactive-box">
						专业承包资质
					</div>
					<div :class="{ active: activeTab === 'tab3' }" @click="activeTab = 'tab3'" class="deactive-box">设计资质</div>
					<div :class="{ active: activeTab === 'tab4' }" @click="activeTab = 'tab4'" class="deactive-box">
						更多热门资质
					</div>
				</div>
				<div class="xiaolv">
					<img src="../assets/img/Group 5157 (1).png" alt="cover" style="width: 100%; height: 160px" />
				</div>
				<div class="service-main">
					<div class="main-son">
						<img style="margin: 40px 0 10px 0" src="../assets/img/Group 7.png" alt="" />
						<p
							style="
								color: #333333;
								font-size: 16px;
								font-family: PingFang SC;
								font-weight: 500;
								line-height: 8px;
								word-wrap: break-word;
							"
						>
							房建工程
						</p>
					</div>
					<div class="main-son">
						<img style="margin: 40px 0 10px 0" src="../assets/img/Group 9.png" alt="" />
						<p
							style="
								color: #333333;
								font-size: 16px;
								font-family: PingFang SC;
								font-weight: 500;
								line-height: 8px;
								word-wrap: break-word;
							"
						>
							市政工程
						</p>
					</div>
					<div class="main-son">
						<img style="margin: 40px 0 10px 0" src="../assets/img/Group 17.png" alt="" />
						<p
							style="
								color: #333333;
								font-size: 16px;
								font-family: PingFang SC;
								font-weight: 500;
								line-height: 8px;
								word-wrap: break-word;
							"
						>
							机电工程
						</p>
					</div>
					<div class="main-son">
						<img style="margin: 40px 0 10px 0" src="../assets/img/Group 19.png" alt="" />
						<p
							style="
								color: #333333;
								font-size: 16px;
								font-family: PingFang SC;
								font-weight: 500;
								line-height: 8px;
								word-wrap: break-word;
							"
						>
							电力工程
						</p>
					</div>
					<div class="main-son">
						<img style="margin: 40px 0 10px 0" src="../assets/img/Group 21.png" alt="" />
						<p
							style="
								color: #333333;
								font-size: 16px;
								font-family: PingFang SC;
								font-weight: 500;
								line-height: 8px;
								word-wrap: break-word;
							"
						>
							水利工程
						</p>
					</div>
					<div class="main-son">
						<img style="margin: 40px 0 10px 0" src="../assets/img/Group 11.png" alt="" />
						<p
							style="
								color: #333333;
								font-size: 16px;
								font-family: PingFang SC;
								font-weight: 500;
								line-height: 8px;
								word-wrap: break-word;
							"
						>
							通信工程
						</p>
					</div>
					<div class="main-son">
						<img style="margin: 40px 0 10px 0" src="../assets/img/Group 15.png" alt="" />
						<p
							style="
								color: #333333;
								font-size: 16px;
								font-family: PingFang SC;
								font-weight: 500;
								line-height: 8px;
								word-wrap: break-word;
							"
						>
							公路工程
						</p>
					</div>
					<div class="main-son">
						<img style="margin: 40px 0 10px 0" src="../assets/img/Group 13.png" alt="" />
						<p
							style="
								color: #333333;
								font-size: 16px;
								font-family: PingFang SC;
								font-weight: 500;
								line-height: 8px;
								word-wrap: break-word;
							"
						>
							更多工程
						</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 资质服务 -->
		<div class="zz" id="five">
			<!-- <img src="../assets/img/b_5ce21aa67ea0a.png" class="swiperimg" style="opacity: 0.1" alt="" /> -->
			<!-- <div
				style="
					position: absolute;
					left: 0;
					z-index: -1;
					width: 100vw;
					height: 800px;
					background: rgba(255, 255, 255, 0.9);
				"
			></div> -->
			<div class="container">
				<p class="zz-font">资质服务就找“资质狗”</p>
				<p class="zz-spfont">6大服务，让你省时省心又省力</p>
				<div class="son-zz">
					<div class="zz-main">
						<div>
							<img src="../assets/img/01.png" alt="" />
						</div>
						<div class="zzright">
							<p
								style="
									font-family: PingFang SC;
									font-size: 18px;
									font-weight: 500;
									line-height: 22px;
									letter-spacing: 0px;
									text-align: left;
								"
							>
								高薪快速办理
							</p>
							<p
								style="
									width: 366px;
									font-family: PingFang SC;
									font-size: 14px;
									font-weight: 400;
									line-height: 20px;
									letter-spacing: 0px;
									text-align: justified;
									color: #808080;
									margin: 10px 0 0 0;
								"
							>
								专业人员跟进办理，熟知业务流程和政策法规，缩短办理周期。
							</p>
						</div>
					</div>
					<div class="zz-main">
						<div>
							<img src="../assets/img/02.png" alt="" />
						</div>
						<div class="zzright">
							<p
								style="
									font-family: PingFang SC;
									font-size: 18px;
									font-weight: 500;
									line-height: 22px;
									letter-spacing: 0px;
									text-align: left;
								"
							>
								信息严格保密
							</p>
							<p
								style="
									width: 366px;
									font-family: PingFang SC;
									font-size: 14px;
									font-weight: 400;
									line-height: 20px;
									letter-spacing: 0px;
									text-align: justified;
									color: #808080;
									margin: 10px 0 0 0;
								"
							>
								所有项目均签订保密合同，严格保护客户信息及资料不外泄。
							</p>
						</div>
					</div>
					<div class="zz-main">
						<div>
							<img src="../assets/img/03.png" alt="" />
						</div>
						<div class="zzright">
							<p
								style="
									font-family: PingFang SC;
									font-size: 18px;
									font-weight: 500;
									line-height: 22px;
									letter-spacing: 0px;
									text-align: left;
								"
							>
								一对一顾问服务
							</p>
							<p
								style="
									width: 366px;
									font-family: PingFang SC;
									font-size: 14px;
									font-weight: 400;
									line-height: 20px;
									letter-spacing: 0px;
									text-align: justified;
									color: #808080;
									margin: 10px 0 0 0;
								"
							>
								办理进度及时反馈，业务疑问随时解答让您体验一对一专业服务
							</p>
						</div>
					</div>
					<div class="zz-main">
						<div>
							<img src="../assets/img/04.png" alt="" />
						</div>
						<div class="zzright">
							<p
								style="
									font-family: PingFang SC;
									font-size: 18px;
									font-weight: 500;
									line-height: 22px;
									letter-spacing: 0px;
									text-align: left;
								"
							>
								收费透明合理
							</p>
							<p
								style="
									width: 366px;
									font-family: PingFang SC;
									font-size: 14px;
									font-weight: 400;
									line-height: 20px;
									letter-spacing: 0px;
									text-align: justified;
									color: #808080;
									margin: 10px 0 0 0;
								"
							>
								所有项目均签署合作协议，价格合理透明，无隐形消费
							</p>
						</div>
					</div>
					<div class="zz-main">
						<div>
							<img src="../assets/img/05.png" alt="" />
						</div>
						<div class="zzright">
							<p
								style="
									font-family: PingFang SC;
									font-size: 18px;
									font-weight: 500;
									line-height: 22px;
									letter-spacing: 0px;
									text-align: left;
								"
							>
								一站式优质服务
							</p>
							<p
								style="
									width: 366px;
									font-family: PingFang SC;
									font-size: 14px;
									font-weight: 400;
									line-height: 20px;
									letter-spacing: 0px;
									text-align: justified;
									color: #808080;
									margin: 10px 0 0 0;
								"
							>
								14年专业资质服务，业务全面，一站式快速办理，解决你的需求。
							</p>
						</div>
					</div>
					<div class="zz-main">
						<div>
							<img src="../assets/img/06.png" alt="" />
						</div>
						<div class="zzright">
							<p
								style="
									font-family: PingFang SC;
									font-size: 18px;
									font-weight: 500;
									line-height: 22px;
									letter-spacing: 0px;
									text-align: left;
								"
							>
								售后服务
							</p>
							<p
								style="
									width: 366px;
									font-family: PingFang SC;
									font-size: 14px;
									font-weight: 400;
									line-height: 20px;
									letter-spacing: 0px;
									text-align: justified;
									color: #808080;
									margin: 10px 0 0 0;
								"
							>
								集团企业信誉保障，7*24小时服务热线售后专人快速处理
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 提供资质服务 -->
		<div class="protect" id="four">
			<div class="container">
				<p class="protect-font">资质狗已为数万家企业提供资质服务</p>
				<p class="protect-spfont">万千企业的一致选择，正是我们实力的体现</p>
				<el-row class="app-container">
					<el-col :span="2" class="service-title-scroll">
						<!-- <a class="el-icon-caret-left" @click="leftSlide"></a> -->
						<img src="../assets/img/进入箭头小备份.png" alt="" @click="leftSlide" />
					</el-col>
					<el-col :span="20">
						<div ref="wrapperCon" class="service-title">
							<div class="box">
								<img src="../assets/img/位图.png" alt="" />
							</div>
							<div class="box">
								<img src="../assets/img/位图.png" alt="" />
							</div>
							<div class="box">
								<img src="../assets/img/位图.png" alt="" />
							</div>
							<div class="box">
								<img src="../assets/img/位图.png" alt="" />
							</div>
							<div class="box">
								<img src="../assets/img/位图.png" alt="" />
							</div>
							<!-- <div class="box" style="background-color: black"></div>
							<div class="box" style="background-color: yellow"></div>
							<div class="box" style="background-color: green"></div>
							<div class="box" style="background-color: blue"></div>
							<div class="box" style="background-color: red"></div> -->
						</div>
					</el-col>
					<el-col :span="2" class="service-title-scroll">
						<!-- <a class="el-icon-caret-right" @click="rightSlide"></a> -->
						<img src="../assets/img/进入箭头小.png" alt="" @click="rightSlide" />
					</el-col>
				</el-row>
			</div>
		</div>
		<!-- 步骤条 -->
		<div class="step">
			<!-- <div
				style="
					position: absolute;
					left: 0;
					z-index: -1;
					width: 100vw;
					height: 200px;
					background: linear-gradient(241deg, #3e6bf7 0%, rgba(74, 116, 245, 0.8) 10%);
				"
			></div> -->
			<div class="container" style="display: flex; align-items: center; justify-content: space-between">
				<div class="step-box">
					<img src="../assets/img/message-text 1.png" style="width: 32px; height: 32px" alt="" />
					<p
						style="
							color: white;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 22px;
							word-wrap: break-word;
						"
					>
						咨询/预约
					</p>
				</div>
				<img src="../assets/img/直线 4备份.png" alt="" />
				<div class="step-box">
					<img src="../assets/img/phone-income 1.png" style="width: 32px; height: 32px" alt="" />
					<p
						style="
							color: white;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 22px;
							word-wrap: break-word;
						"
					>
						回电接洽
					</p>
				</div>
				<img src="../assets/img/直线 4备份.png" alt="" />
				<div class="step-box">
					<img src="../assets/img/privacy policy 1.png" style="width: 32px; height: 32px" alt="" />
					<p
						style="
							color: white;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 22px;
							word-wrap: break-word;
						"
					>
						专业高效办理
					</p>
				</div>
				<img src="../assets/img/直线 4备份.png" alt="" />
				<div class="step-box">
					<img src="../assets/img/page-flip 1.png" style="width: 32px; height: 32px" alt="" />
					<p
						style="
							color: white;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 22px;
							word-wrap: break-word;
						"
					>
						专人售后服务
					</p>
				</div>
			</div>
			<div class="container">
				<div class="mast" @click="clickcon">
					<p
						style="
							font-family: PingFang SC;
							font-size: 14px;
							font-weight: 500;
							color: #465deb;
							letter-spacing: 0px;
							text-align: center;
							cursor: pointer;
						"
					>
						立即办理
					</p>
				</div>
			</div>
		</div>
		<!-- 页脚 -->
		<div class="footer">
			<div class="container" style="display: flex; justify-content: space-between">
				<div style="padding-top: 40px">
					<p
						style="
							font-family: PingFang SC;
							font-size: 14px;
							font-weight: 400;
							line-height: 14px;
							letter-spacing: 0px;
							text-align: left;
							color: #999;
							margin: 10px 0;
						"
					>
						关于我们
					</p>
					<p
						style="
							font-family: PingFang SC;
							font-size: 14px;
							font-weight: 400;
							line-height: 24px;
							letter-spacing: 0px;
							text-align: left;
							color: #999;
						"
					>
						湖北资质狗信息技术有限公司
					</p>
					<p
						style="
							font-family: PingFang SC;
							font-size: 14px;
							font-weight: 400;
							line-height: 24px;
							letter-spacing: 0px;
							text-align: left;
							color: #999;
							width: 520px;
						"
					>
						公司秉承“专业、高效、诚信、放心”的服务理念，主要从事设计、施工、监理、勘察方面的资质服务！
					</p>
				</div>
				<div class="fjs">
					<p
						style="
							color: #808080;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 14px;
							word-wrap: break-word;
						"
					>
						扫码关注公众号
					</p>
					<img src="../assets/img/资质狗公众号二维码.jpg" alt="" />

					<p
						style="
							color: #808080;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 14px;
							word-wrap: break-word;
							margin-left: 50px;
						"
					>
						扫码进入小程序
					</p>
					<img src="../assets/img/资质狗小程序.jpg" alt="" />
				</div>
			</div>
			<div class="container">
				<a
					class="wwwwwwa"
					style="text-decoration: none; color: #808080"
					target="_blank"
					href="https://beian.miit.gov.cn/#/Integrated/index"
					>鄂ICP备2024033225号</a
				>
			</div>
		</div>
		<!--立即办理dialog -->
		<el-dialog title="业务办理" :show-close="false" :visible.sync="dialogFormVisible" width="700px">
			<div class="wwwwwww">
				<p>联系人</p>
				<el-input type="text" style="width: 100%" v-model="one" />
			</div>
			<div class="wwwwwww">
				<p>联系方式</p>
				<el-input type="text" style="width: 100%" v-model="two" />
			</div>
			<div class="wwwwwww">
				<p>公司名称</p>
				<el-input type="text" style="width: 100%" v-model="three" />
			</div>
			<div class="dialog-footer">
				<!-- <el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogFormVisible = false">确 定</el-button> -->
				<div class="quxiao" @click="dialogFormVisible = false">取消</div>
				<div class="queding" @click="dialogFormVisible = false">确定</div>
			</div>
		</el-dialog>
		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
			<span>这是一段信息</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'HelloWorld',
	props: {
		msg: String,
		interval: {
			type: Number,
			default: 5000
		}
	},
	data() {
		return {
			dialogVisible: false,
			showyes: false,
			activeTab: 'tab1',
			one: '',
			two: '',
			three: '',
			dialogFormVisible: false,
			form: {
				name: '',
				phonenumber: '',
				companyname: ''
			},
			showgz: false,
			showcx: false
		};
	},
	created() {
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

		if (isMobile) {
			console.log('当前在手机端');
			this.showyes = true;
			// #小程序://资质狗/rMGtES2yHEUtlcw
			// this.dialogVisible = true;
			location.href = 'weixin://dl/business/?t=SK1KuqG41Dd';
		} else {
			console.log('当前在PC端');
		}
	},
	methods: {
		onew() {
			this.showcx = false;
		},
		twow() {
			this.showcx = true;
		},
		out() {
			this.showgz = false;
		},
		//龚总好移入
		handleMouseEnter() {
			this.showgz = true;
		},
		// 立即办理
		clickcon() {
			this.dialogFormVisible = true;
		},
		leftSlide() {
			// 保存滚动盒子左侧已滚动的距离
			let left = this.$refs.wrapperCon.scrollLeft;
			let num = 0;
			clearInterval(this.timer);
			this.timer = null;
			this.timer = setInterval(() => {
				//   !left:已经滚动到最左侧
				//   一次性滚动距离（可调节）
				if (!left || num >= 100) {
					// 停止滚动
					clearInterval(this.timer);
					this.timer = null;
					return;
				}
				// 给滚动盒子元素赋值向左滚动距离
				this.$refs.wrapperCon.scrollLeft = left -= 30;
				// 保存向左滚动距离（方便判断一次性滚动多少距离）
				num += 30;
			}, 20);
			// 20：速度（可调节）
		},
		rightSlide() {
			// 保存滚动盒子左侧已滚动的距离
			let left = this.$refs.wrapperCon.scrollLeft;
			// 保存元素的整体宽度
			const scrollWidth = this.$refs.wrapperCon.scrollWidth;
			// 保存元素的可见宽度
			const clientWidth = this.$refs.wrapperCon.clientWidth;
			let num = 0;
			clearInterval(this.timer);
			this.timer = setInterval(() => {
				// 已经滚动距离+可见宽度
				// left+clientWidth>=scrollWidth：滚动到最右侧
				// num>=300一次性滚动距离
				if (left + clientWidth >= scrollWidth || num >= 100) {
					clearInterval(this.timer);
					return;
				}
				// 给滚动盒子元素赋值向左滚动距离
				this.$refs.wrapperCon.scrollLeft = left += 30;
				// 保存向左滚动距离（方便判断一次性滚动多少距离）
				num += 30;
			}, 20);
			// 20：速度（可调节）
		}
	}
};
</script>
<style>
.showstyle {
	opacity: 0.1;
}
body {
	margin: 0 !important;
}
</style>
<style scoped lang="scss">
.dialog-footer {
	display: flex;
	margin-top: 20px;
	margin-left: 80%;
}
.queding {
	border: 1px solid #ccc;
	width: 80px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	border-radius: 5px;
	background: #465deb;
	color: #fff;
	margin-left: 10px;
	cursor: pointer;
}
.quxiao {
	border: 1px solid #ccc;
	width: 80px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	border-radius: 5px;
	cursor: pointer;
}
.wwwwwww {
	display: flex;
	margin-top: 20px;
	align-items: center;
	// justify-content: space-between;
	p {
		width: 80px;
	}
}
.was {
	position: absolute;
	right: 90px;
	img {
		width: 180px;
		height: 180px;
	}
}
.mouser {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 12px 0;
	p {
		// 公众号
		color: #333333;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 500;
		word-wrap: break-word;
	}
}
.header {
	width: 100%;
}
.active {
	background-color: #465deb !important;
	color: #fff !important;
	cursor: pointer;
}
.hoh {
	width: 140px;
	height: 32px;
	background: #fff;

	line-height: 32px;
	border: 1px solid #465deb;
	border-radius: 5px;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 500;
	margin: 15px auto;
	letter-spacing: 0px;
	text-align: center;
	color: #465deb;
	&:hover {
		background: #465deb;
		color: #fff;
	}
}
.ho {
	cursor: pointer;
	width: 140px;
	height: 32px;
	background: #fff;
	line-height: 32px;
	border: 1px solid #465deb;
	border-radius: 5px;
	margin: 60px auto 0;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0px;
	text-align: center;
	color: #465deb;

	&:hover {
		background: #465deb;
		color: #fff;
	}
}
.headerbox {
	display: flex;
	// height: 60px;
	align-content: center;
	// border: 1px solid red;
	p {
		cursor: pointer;
		margin: 0 20px;
		// 首页
		color: #ccc;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 16px;
		word-wrap: break-word;
		a {
			color: black;
			&:hover {
				color: #465deb;
			}
		}
	}
}
.swiperimg {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
::v-deep .el-carousel__container {
	height: 520px;
}
.app-container {
	margin-bottom: 40px;
}
.box {
	width: 200px;
	flex-shrink: 0;
	height: 286px;
	background-color: red;
	margin: 0 50px 0 10px;
}
.service-title {
	display: flex;
	// justify-content: space-between;
	overflow: hidden;
	// height: 35px;
}
.service-title-scroll {
	font-size: 30px;
	margin-top: 120px;
	text-align: center;
	height: 35px;
	line-height: 35px;
}
.service-title-block {
	text-align: center;
	width: 130px;
	margin: 0 10px;
	box-shadow: inset 0 1px 0 0 #fff6af;
	background: #ffec64 linear-gradient(to bottom, #ffec64 5%, #ffab23 100%);
	border-radius: 6px;
	border: 1px solid #ffaa22;
	display: inline-block;
	cursor: pointer;
	color: #333333;
	font-size: 15px;
	font-weight: bold;
	padding: 6px 24px;
	text-decoration: none;
	text-shadow: 0 1px 0 #ffee66;
	&:hover {
		background: #ffab23 linear-gradient(to bottom, #ffab23 5%, #ffec64 100%);
	}
	&:active {
		position: relative;
		top: 5px;
	}
}

.container {
	width: 1200px;
	// height: 1000px;
	// border: 1px solid red;
	margin: 0 auto;
}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
.fjs {
	display: flex;
	align-items: center;
	padding-top: 40px;
	p {
		margin-right: 10px;
	}
	img {
		width: 124px;
		height: 124px;
	}
}
.main {
	width: 100%;
	.footer {
		padding-bottom: 100px;
		width: 100%;
		background: black;
		// height: 74px;
	}
	.protect {
		width: calc(100vw - 30px);
		// background: pink;
		.protect-font {
			font-family: PingFang SC;
			font-size: 32px;
			font-weight: 500;
			line-height: 40px;
			letter-spacing: 0px;
			text-align: center;
			margin: 140px 0 20px 0;
		}
		.protect-spfont {
			font-family: PingFang SC;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: 0px;
			text-align: center;
			color: #808080;
			margin: 0 0 60px 0;
		}
		.protect-main {
			width: 100%;
			background: #ccc;
			display: flex;

			justify-content: spa;
			margin-bottom: 100px;
		}
		.protect-box {
			margin: 40px 0;
			width: 200px;
			height: 286px;
			background: #fff;
		}
	}
	.step {
		width: 100%;
		background: blue;
		height: 200px;
		z-index: -2;
		background-image: url('../assets/img/jjjjhhhhh.png');
		background-repeat: no-repeat; /* 禁止重复铺满 */
		background-position: center; /* 居中对齐 */
		background-size: cover; /* 调整背景图大小以完全显示 */

		.mast {
			border-radius: 3px;
			width: 140px;
			height: 32px;
			// border: 1px solid #fff;
			background: #fff;
			line-height: 32px;
			margin: 0 auto;
		}
		.step-box {
			margin: 30px 0;
			text-align: center;
			P {
				margin: 10px 0;
			}
		}
	}
	width: 100%;
	// background: red;
	.top {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 60px;
		background: #fff;
		// border: 1px solid blue;
	}
	.swiper {
		width: 100%;
		// height: 520px;
		// border: 1px solid black;
	}
	.categroy {
		width: 100%;
		// border: 1px solid yellow;
		.son-box {
			margin: 50px auto;
			display: flex;
			// border: 1px solid pink;
			// background: #ccc;
			justify-content: space-between;
			.box-cate {
				width: 285px;
				height: 224px;
				// border: 1px solid black;
				background: #f7f7f7;
				text-align: center;
				// padding: 40px 20px 40px 20px;
			}
		}

		.font-cate {
			font-family: PingFang SC;
			font-size: 32px;
			font-weight: 500;
			line-height: 40px;
			letter-spacing: 0px;
			text-align: center;
			margin-top: 120px;
		}
	}

	.zz {
		width: 100%;
		height: 800px;
		z-index: -2;
		background-image: url('../assets/img/20231215.png');
		background-repeat: no-repeat; /* 禁止重复铺满 */
		background-position: center; /* 居中对齐 */
		background-size: cover; /* 调整背景图大小以完全显示 */
	}
	.zzright {
		// display: flex;
	}
	.son-zz {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		// margin-bottom: 100px;
		.zz-main {
			width: 590px;
			height: 152px;
			// border: 1px solid blue;
			// background: #fff;
			// background-color: rgba(255, 255, 255, 0); /* 背景颜色并设置透明度为50% */
			margin: 20px 0;
			display: flex;
			align-items: center;
			justify-content: space-around;
			&:hover {
				background: #fff;
			}
		}
	}
	.zz-font {
		font-family: PingFang SC;
		font-size: 32px;
		font-weight: 500;
		line-height: 40px;
		letter-spacing: 0px;
		text-align: center;
		// margin: 80px 0 20px 0;
		padding-top: 100px;
	}
	.zz-spfont {
		font-family: PingFang SC;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: 0px;
		text-align: center;
		color: #808080;
		margin: 0 0 30px 0;
	}

	.service {
		.service-main {
			width: 100%;
			margin-top: 60px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.main-son {
				margin: 20px 0;
				width: 285px;
				height: 178px;
				border: 1px solid #e6e6e6;
				text-align: center;
			}
		}
		.xiaolv {
			width: 100%;
			height: 100px;
			background: #f7f7f7;
		}
		width: calc(100vw - 30px);
		// background: pink;
		.service-font {
			font-family: PingFang SC;
			font-size: 32px;
			font-weight: 500;
			line-height: 40px;
			letter-spacing: 0px;
			text-align: center;
			margin: 100px 0 10px 0;
		}
		.service-spfont {
			font-family: PingFang SC;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: 0px;
			text-align: center;
			color: #808080;
			margin-bottom: 40px;
		}
		.son-service {
			margin-bottom: 40px;
			width: 100%;
			height: 40px;
			background: #f7f7f7;
			display: flex;
			text-align: center;
			line-height: 40px;
			.active-box {
				width: 25%;
				height: 40px;
				background: blue;
				p {
					color: #fff;
					// 专业承包资质
					// color: #333333;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 500;

					word-wrap: break-word;
				}
			}
			.deactive-box {
				cursor: pointer;
				width: 25%;
				height: 40px;
				background: #f7f7f7;

				color: #000;
				// 专业承包资质
				color: #333333;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 500;

				word-wrap: break-word;
			}
		}
	}
	.hot {
		width: 100%;
		// border: 1px solid blue;
		background: #f7f7f7;
		padding-top: 10px;
		.son-hot {
			display: flex;
			justify-content: space-between;
		}
		.hot-font {
			margin: 100px 0 50px 0;
			font-family: PingFang SC;
			font-size: 32px;
			font-weight: 500;
			line-height: 40px;
			letter-spacing: 0px;
			text-align: center;
		}
		.hot-box {
			margin-bottom: 40px;
			width: 285px;
			height: 521px;
			// border: 1px solid blue;
			text-align: center;
			background: #fff;
		}
	}
}
</style>
